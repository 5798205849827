<template>
	<div>
		<v-container fluid class="reserve-wrap pa-0">
			<v-row class="w-100 ma-0 pa-0">
				<v-col cols="12" md="8" class="flex-center flex-column reserve-bg">
					<div class="form-wrap pa-4 pa-md-10" data-aos="fade-down" data-aos-duration="700">
						<h1 class="font-weight-bold w-100 mb-0">
							{{ $t('reserve') }}
						</h1>
						<p class="w-100 text--disabled mb-7">{{ $t('allRequired') }}</p>
						<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field filled v-model="arrivalFormatted" @blur="data.arrival = parseDate(arrivalFormatted)" :label="$t('dolazak')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="data.arrival" @input="menu = false"></v-date-picker>
						</v-menu>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field filled v-model="departureFormatted" @blur="data.departure = parseDate(departureFormatted)" :label="$t('odlazak')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="data.departure" @input="menu2 = false"></v-date-picker>
						</v-menu>
						<v-text-field type="number" class="adultsNum" :label="$t('osobe')" append-icon="mdi-account" filled v-model="data.adults"></v-text-field>
						<v-select :label="$t('smjestaj')" :items="apts" item-text="name" item-value="name" filled v-model="data.apt"></v-select>
						<v-btn color="#059bda" dark tile height="56" class="w-100" depressed @click="reserve(data)">{{ $t('reserve') }}</v-btn>
					</div>
				</v-col>
				<v-col cols="12" md="4" class="flex-center flex-column reserve-color">
					<img src="../assets/img/logo-light.png" alt="Villa Marina" height="250">
				</v-col>
			</v-row>
		</v-container>

		<v-dialog v-model="reservationDialog" max-width="500px" @click-outside="closeRes">
			<v-card tile >
				<v-toolbar dark color="#059bda" flat class="px-0">
					<v-toolbar-title class="font-weight-bold">Rezervacija</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeRes()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text class="pt-8 px-4 flex-center flex-column" v-if="resError">
					<p class="red--text w-100 text-center">Neka polja nisu popunjena. Molimo pokušajte ponovno.</p>
				</v-card-text>
				<v-card-text class="pt-5 px-4 flex-center flex-column mb-4" v-else>
					<p class="text--primary w-100 font-weight-bold">Detalji Vaše rezervacije</p>
					<p class="w-100"><span class="text--primary">Dolazak:</span> {{ arrivalFormatted }}</p>
					<p class="w-100"><span class="text--primary">Odlazak:</span> {{ departureFormatted }}</p>
					<p class="w-100"><span class="text--primary">Broj osoba:</span> {{ data.adults }}</p>
					<p class="w-100"><span class="text--primary">Apartman / Studio:</span> {{ data.apt }}</p>

					<v-text-field class="w-100" label="Vaše puno ime *" v-model="info.name"></v-text-field>
					<v-text-field class="w-100" label="E-mail *" v-model="info.email"></v-text-field>

					<div class="w-100 flex-center flex-column" v-if="resLoading">
						<p class="w-100 text--secondary text-center">Slanje...</p>
						<v-progress-linear color="#059bda" indeterminate rounded height="6"></v-progress-linear>
					</div>

					<v-alert class="w-100 mb-0 mt-4" type="success" v-if="resSuccess">
						Zahtjev za rezervaciju izvršen. Odgovor Vam stiže na mail kroz nekoliko dana.
					</v-alert>

					<v-alert class="w-100 mb-0 mt-4" type="error" v-if="resFailed">
						Došlo je do pogreške. Molimo kontaktirajte nas na +387 36 044 242 ili na info@villa-marina.ba
					</v-alert>
				</v-card-text>


				<v-card-actions v-if="!resError">
					<v-spacer></v-spacer>
					<v-btn color="error" text @click="closeRes()">
						Odustani
					</v-btn>
					<v-btn color="#059bda" text @click="submitReservation" :disabled="!info.name || !info.email">
						Potvrdi
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import emailjs from 'emailjs-com'
export default {
	name: 'Reserve',
	data: () => ({
		resError: false,
		resFailed: false,
		resSuccess: false,
		resLoading: false,
		reservationDialog: false,
		arrivalFormatted: null,
		departureFormatted: null,
		data: {
			arrival: null,
			departure: null,
			adults: null,
			apt: null,
		},
		info: {
			name: null,
			email: null
		},
		menu: false,
		menu2: false,
	}),
	watch: {
		data: {
			handler(val) {
				this.arrivalFormatted = this.formatDate(this.data.arrival)
				this.departureFormatted = this.formatDate(this.data.departure)
			},
			deep: true
		}
	},
	computed: {
		apts: function() {
			let all = this.$store.getters.apartments.items;

			return all;
		}
	},
	methods: {
		closeRes() {
			let self = this;

			Object.keys(self.data).forEach(function(index) {
				self.data[index] = null;
			})
			
			this.reservationDialog = false;
		},
		formatDate(date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		parseDate(date) {
			if (!date) return null

			const [month, day, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},
		reserve(data) {
			if(!Object.values(data).every(o => o != null)) {
				this.resError = true
				this.reservationDialog = true
			} else {
				this.resError = false
				this.reservationDialog = true
			}
		},
		submitReservation() {
			let dates = {
				newArrival: this.arrivalFormatted,
				newDeparture: this.departureFormatted
			}

			let data = Object.assign(this.data, this.info, dates)

			this.resLoading = true;

			emailjs.send('service_649v3h6', 'template_o7edow1', data, 'gUsIcqluBMRuDcjRm').then((result) => {
				this.resLoading = false;
				this.resSuccess = true;
			}, (error) => {
				this.resLoading = false;
				this.resFailed = true;
			});
		}
	}
}
</script>
<style lang="scss">
.reserve-wrap {
	margin-top: -90px;
}
.reserve-bg {
	min-height: 100vh;
	background: linear-gradient(to left, rgba(255, 255, 255, .8), rgba(255, 255, 255, .2)), url("../assets/img/neum.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.reserve-color {
	min-height: 100vh;
	background-color: #059bda;
	padding-top: 90px;
}
.form-wrap {
	width: 60%;
	min-height: 60vh;
	margin-top: 90px;
	background-color: #FFF;
}
@media(max-width: 768px) {
	.form-wrap {
		width: 90%;
	}
	.reserve-color {
		min-height: 50vh;
		padding-top: 0px !important;
	}
}
@media(max-height: 660px) {
	.reserve-bg {
		padding-bottom: 50px;
	}
}
</style>